import { css } from '@emotion/react';

const baseColor = '#233333';
const fontFamily = `"Hiragino Kaku Gothic ProN", "Hiragino Sans",
 "Arial", "Yu Gothic", "Meiryo", sans-serif`;

export const commonCss = css`
  // Base
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  html {
    font-size: 100%;
  }
  body {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    font-family: ${fontFamily};
    color: ${baseColor};
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    transition: none;
    @media (max-width: 767px) {
      font-size: 0.9rem;
    }
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  img {
    max-width: 100%;
    height: auto;
    backface-visibility: hidden;
  }
  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em,
  dfn {
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const commonComp = css`
  .container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  @media (min-width: 768px) {
    .sp_visible {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .pc_visible {
      display: none;
    }

    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

export const modalStyle = css`
  .ReactModal__Body--open {
    overflow: hidden;
    left: 0;
    right: 0;
    margin: auto;
  }

  .overlay-base {
    padding: 4rem 3rem;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: background-color, opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    * {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-backface-visibility: hidden;
    }
    @media (max-width: 767px) {
      padding: 60px 15px 20px;
    }
  }

  .overlay-after {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }

  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  .content-base {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin: 0 auto;
    border: 0;
    outline: 0;
    height: 0%;
    width: 0%;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    border-radius: 12px;
    padding: 3rem;
    box-sizing: border-box;
    overflow: scroll;
    display: block;
  }

  .content-after {
    width: 100%;
    max-width: 800px;
    height: auto;
    max-height: 100%;
    background-color: #fff;
  }

  .content-before {
    width: 100%;
    max-width: 800px;
    height: auto;
    max-height: 100%;
    background-color: transparent;
  }

  .cast-name {
    font-size: 1.2rem;
    color: #34187e;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px;
    > small {
      font-size: 0.8em;
      text-align: center;
      display: block;
      margin-top: 0.3rem;
    }
  }

  .cast-image {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .modal_wrapper {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }

  .image-wrapper {
    margin: 0 auto 30px;
    text-align: center;
    max-width: 500px;
    .gatsby-image-wrapper {
      margin-top: 0;
    }
  }

  .profile_dl {
    & + & {
      &:not(.not_title) {
        margin-top: 30px;
      }

      &.not_title {
        margin-top: 10px;
      }
    }

    dt {
      font-weight: 700;
      margin-bottom: 6px;
    }

    dd {
      a {
        color: #ec8fbc;
        text-decoration: none;
        @media (min-width: 768px) {
          transition: all 0.3s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .modal-close {
    pointer-events: none;
    border: none;
    background: 0 0;
    padding: 0;
    -webkit-appearance: none;
    position: fixed;
    right: 35px;
    top: 8px;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    transition: none;

    &::before,
    &::after {
      display: block;
      content: ' ';
      position: absolute;
      top: 14px;
      left: 23px;
      width: 4px;
      height: 22px;
      border-radius: 4px;
      background: #fff;
      -webkit-transition: background 0.2s ease-in-out;
      transition: background 0.2s ease-in-out;
    }
    &::before {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &::after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    span {
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0 !important;
      border: 0 !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden;
    }

    @media (max-width: 767px) {
      right: 0;
    }
  }

  .gatsby-image-wrapper [data-main-image] {
    opacity: 1 !important;
    transform: translateZ(1) !important;
    transition: inherit;
    will-change: opacity;
  }
`;
