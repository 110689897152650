import { css } from '@emotion/react';

import black_7th_Background from '../../images/7th/black_7th_Background_wh.jpg';
import black_7th_Background2x from '../../images/7th/black_7th_Background_wh@2.jpg';

import black_7th_Background_webp from '../../images/7th/black_7th_Background_wh.webp';
import black_7th_Background2x_webp from '../../images/7th/black_7th_Background_wh@2.webp';

export const bgPattern7th = css`
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  background-image: url(${black_7th_Background});
  background-image: image-set(url(${black_7th_Background}) 1x, url(${black_7th_Background2x}) 2x);
  background-image: -webkit-image-set(url(${black_7th_Background_webp}) 1x, url(${black_7th_Background2x_webp}) 2x);
`;
