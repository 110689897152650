import React from 'react';
import { css } from '@emotion/react';
import { bgPattern7th } from './styles';

export interface SectionParam {
  isWhite?: Boolean;
  addClass?: String;
  setCss?: any;
}

const Section7thBlack: React.FC<SectionParam> = ({ children, isWhite = false, addClass, setCss }) => {
  return (
    <section
      className={`${addClass ? `${addClass} n-section` : 'n-section'}`}
      css={[bgPattern7th, sectionStyle, setCss ? setCss : null]}
    >
      <div className="container">{children}</div>
    </section>
  );
};

const sectionStyle = css`
  padding: 60px 0 70px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 40px 0 50px;
  }
`;

export default Section7thBlack;
